import http from '../clients/Axios'

export default class PenerimaanService {
  async paginate(pageNumber, pageSize = 10, sortField, sortOrder, filters) {
    pageNumber = pageNumber === undefined ? 1 : pageNumber
    const sort = sortOrder === 1 ? '-' + sortField : sortField

    let filters_param = ''

    if (filters) {
      const keys = Object.keys(filters)

      keys.forEach((el) => {
        if (filters[el].value) {
          filters_param =
            filters_param + '&filter[' + el + ']=' + filters[el].value
        }
      })
    }

    const res = await http.get(
      `/penerimaan/paginate?page[number]=${pageNumber}&page[size]=${pageSize}&sort=${sort}${filters_param}`
    )

    return res.data
  }

  async get(params = '') {
    const res = await http.get(`/penerimaan${params}`)
    return res
  }

  async getById(param) {
    param = param ? param : ''
    const res = await http.get(`/penerimaan/${param}`)
    return res
  }

  async add(form) {
    const res = await http.post('/penerimaan', form)
    return res
  }

  async update(id, form) {
    const res = await http.put(`/penerimaan/${id}`, form)
    return res
  }

  async delete(id) {
    const res = await http.delete(`/penerimaan/${id}`)
    return res
  }

  async print(id) {
    return http({
      url: `/penerimaan/${id}/print`,
      method: 'GET',
      responseType: 'blob',
    })
  }
}
